import React from "react";
import ReactDOM from 'react-dom/client';
import { FaEnvelope, FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import bgImage from '../assets/images/contact-section.jpg';

export default function ContactBanner() {
    return (
        <section
        className="page-section section-contact-banner w-100 bg-position-center py-5 d-flex flex-column justify-content-center position-relative"
        style={{backgroundImage: `url(${bgImage})`}}>
            <h5 className="text-center font-urbanist mb-1 fw-bold text-white">CONTACT NOW</h5>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="card contact-banner-card rounded-4">
                            <div className="card-body border-0 rounded-4">
                                <div className="contact-banner-card-icon text-center d-flex
                                align-items-center justify-content-center mb-4 text-white bg-dark">
                                    <FaEnvelope />
                                </div>
                                <h4 className="contact-banner-card-title text-center mb-1 lh-sm">
                                    Email
                                </h4>
                                <a href="mailto:contact@hirejunaid.com"
                                className="d-block text-center text-decoration-none text-dark">contact@hirejunaid.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="card border-0 rounded-4">
                            <div className="card-body card-body-inverted border-0 rounded-4">
                                <div className="contact-banner-card-icon text-center
                                text-dark d-flex align-items-center justify-content-center mb-4">
                                    <FaWhatsapp />
                                </div>
                                <h4 className="contact-banner-card-title text-center text-white mb-1 lh-sm">
                                    Whatsapp
                                </h4>
                                <a href="https://wa.me/14085209556"
                                className="text-center d-block text-white text-decoration-none">+1 (408) 520-9556</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="card rounded-4">
                            <div className="card-body border-0 rounded-4">
                                <div className="contact-banner-card-icon text-center d-flex
                                align-items-center justify-content-center mb-4 bg-dark text-white">
                                    <FaPhoneAlt />
                                </div>
                                <h4 className="contact-banner-card-title text-center mb-1 lh-sm text-dark">
                                    Phone
                                </h4>
                                <a href="https://wa.me/14085209556"
                                className="text-center d-block text-dark text-decoration-none">+1 (408) 520-9556</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}