import React from "react";
import ReactDOM from 'react-dom/client';
import Banner from "../templates/banner";
import Junaid from '../assets/images/junaid.png';

export default function About() {
    return (
        <>
            <Banner title={'About - HireJunaid'} />
            <div className="container my-5">
                <div className="row">
                    <div className="col-12 col-lg-6 about-image d-flex align-items-center justify-content-center">
                        <img src={Junaid} width="433" height="577" alt="Hirejunaid" title="About - HireJunaid" loading="lazy" decoding="async" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <h1>About</h1>
                        <p className="lh-base text-dark lead">
                            Hi, I'm Junaid—an Experienced WordPress Developer with a strong background in both front-end and back-end
                            development. Proficient in creating custom themes and plugins, optimizing website performance,
                            and ensuring responsive design. Skilled in PHP, HTML5, CSS3, JavaScript, jQuery, and Restful APIs,
                            with a keen eye for UI/UX and a solid understanding of GitHub version control. Adept at collaborating 
                            with cross-functional teams to deliver high-quality, user-focused web solutions.
                        </p>
                    </div>
                </div>
            </div>

        </>

    )
}