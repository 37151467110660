import React from "react";
import ReactDOM from 'react-dom/client';
import { Link, useLocation } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="site-footer py-2  border-top d-flex align-items-center justify-content-center container">
            <Link to="/" aria-label="Hirejunaid" className="text-decoration-none text-capitalize text-dark fw-normal fs-4">HireJunaid</Link>
        </footer>
    )
}