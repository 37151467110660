import React from "react";
import ReactDOM from 'react-dom/client';
import { useEffect } from "react";
import Banner from "../templates/banner";
import ContactBanner from "../templates/contact";

export default function Contact() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);    

    return (
        <>
        <Banner title={'Contact - HireJunaid'} />
        <ContactBanner />
        </>
    )
}