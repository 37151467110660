import React, { useState } from "react";
import ReactDOM from 'react-dom/client';
import ContactBanner from "../templates/contact";
import { FaHtml5, FaCss3Alt, FaJs, FaBootstrap, FaReact, FaWordpress, FaPhp } from "react-icons/fa";
import { BsFiletypeScss } from "react-icons/bs";
import { DiJqueryLogo, DiResponsive } from "react-icons/di";
import { useEffect } from "react";
import emailjs from 'emailjs-com';
import Resume from '../assets/images/Resume-Junaid-Asghar.pdf';

export default function Home() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        document.title = 'Home - HireJunaid'
    }, []);

    return (
        <>
            <section className="hero w-100 vh-100 d-flex align-items-center justify-content-center">
                <div className="position-relative z-2">
                    <h3 className="text-center fw-bold text-dark font-urbanist mb-0">Expert in</h3>
                    <h1 className="fw-bold lh-1 dynamic-hero-text">WordPress Development</h1>
                    <p className="hero--intro text-center text-dark font-urbanist fw-bold mx-auto">
                        Our services are designed to help companies define and make their unique value proposition known.
                    </p>
                    <div className="d-flex align-items-center justify-content-center">
                        <a className="global-button mx-auto d-inline-block text-decoration-none" download href={Resume}>Download CV &#10230;</a>
                    </div>
                </div>
            </section>

            <TechStack />
            <ContactBanner />
        </>
    );
}

function TechStack() {
    const stacks = [
        { tech: 'HTML', description: 'Expert in crafting clean, semantic HTML for better SEO and accessibility', icon: <FaHtml5 />, isInverted: false },
        { tech: 'CSS', description: 'Skilled in advanced CSS techniques, including animations, transitions, and responsive design principles.', icon: <FaCss3Alt />, isInverted: true },
        { tech: 'SCSS', description: 'Experienced with SCSS for modular and maintainable CSS, leveraging variables, mixins, and nesting.', icon: <BsFiletypeScss />, isInverted: true },
        { tech: 'Bootstrap', description: 'Skilled in using Bootstrap for rapid, responsive design and UI component integration.', icon: <FaBootstrap />, isInverted: false },
        { tech: 'Responsive Design', description: 'Experienced in creating responsive and adaptive designs using CSS Grid, Flexbox, and media queries.', icon: <DiResponsive />, isInverted: false },
        { tech: 'JS', description: 'Expert in JavaScript, including ES6+ features, asynchronous programming, and dynamic DOM manipulation.', icon: <FaJs />, isInverted: true },
        { tech: 'jQuery', description: 'Experienced in jQuery for simplifying DOM manipulation, event handling, and AJAX requests.', icon: <DiJqueryLogo />, isInverted: true },
        { tech: 'React', description: 'Proficient in React for building dynamic, component-based user interfaces with state management and hooks.', icon: <FaReact />, isInverted: false },
        { tech: 'WordPress', description: 'Deep understanding of WordPress for theme and plugin development, including custom post types and advanced custom fields.', icon: <FaWordpress />, isInverted: false },
        { tech: 'PHP', description: 'Skilled in PHP for server-side scripting, including database interactions and backend logic development', icon: <FaPhp />, isInverted: true }
    ];

    return (
        <section className="page-section section-tech-stack my-5">
            <h2 className="text-center fw-bold page-section--heading mb-4">My
                <span className="gradient-text">Tech</span> Stack</h2>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 d-flex flex-wrap gap-4">
                        {stacks.map((stack, index) => (
                            <TechCard key={index} stack={stack} />
                        ))}
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="position-sticky top-0 h-auto border px-3 py-3">
                            <h3 className="text-dark font-urbanist fw-bold mb-3 text-center">Contact Now</h3>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function TechCard({ stack }) {
    return (
        <div className="tech-stack-card card border-0">
            <div className={`card-body shadow border-0 ${stack.isInverted ? 'tech-stack-card-inverted' : ''}`}>
                <div className={`card-icon d-flex justify-content-center mb-3
                ${stack.isInverted ? 'text-white' : 'text-black'} `}>
                    {stack.icon}
                </div>
                <h3 className={`font-urbanist mb-2 text-center fw-normal lh-1 ${stack.isInverted ? 'text-white' : 'text-dark'}`}>{stack.tech}</h3>
                <p className={`mb-0 text-center lh-base w-75 mx-auto fw-normal ${stack.isInverted ? 'text-white' : 'text-muted'}`}>{stack.description}</p>
            </div>
        </div>
    );
}

function ContactForm() {
    const [formData, setFormData] = useState({
        from_form_name: '',
        from_company: '',
        from_email: '',
        from_phone: '',
        message: '',
        honeypot: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Simple validation to prevent honeypot submissions
        if (formData.honeypot) {
            return; // Bot detected, exit early
        }

        emailjs.sendForm('service_iwo3nuv', 'template_80c8cy7', e.target, 'qaVEzCDrqd84--qUd')
            .then((result) => {
                alert('Message sent successfully!');
                setFormData({
                    from_form_name: '',
                    from_company: '',
                    from_email: '',
                    from_phone: '',
                    message: '',
                    honeypot: ''
                });
            }, (error) => {
                console.error('Error:', error);
                alert('Failed to send message.' + ' ' + error);
            });
    };

    return (
        <form onSubmit={handleSubmit} className="contact-form">
            <div className="contact-form-field mb-3">
                <input
                    type="text"
                    name="from_form_name"
                    className="form-control"
                    id="from_form_name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="contact-form-field mb-3">
                <input
                    type="text"
                    name="from_company"
                    className="form-control"
                    id="from_company"
                    placeholder="Your Company"
                    value={formData.company}
                    onChange={handleChange}
                />
            </div>
            <div className="contact-form-field mb-3">
                <input
                    type="email"
                    name="from_email"
                    className="form-control"
                    id="from_email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="contact-form-field mb-3">
                <input
                    type="tel"
                    name="from_phone"
                    className="form-control"
                    id="from_phone"
                    placeholder="Your Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                />
            </div>
            <div className="contact-form-field mb-3">
                <textarea
                    name="message"
                    className="form-control resize-none"
                    id="message"
                    rows="4"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    style={{resize: 'none'}}
                ></textarea>
            </div>

            <input
                type="text"
                name="honeypot"
                style={{ display: 'none' }}
                value={formData.honeypot}
                readOnly
                onChange={handleChange}
            />
            <button type="submit" className="btn btn-primary d-block w-100">Send Message</button>
        </form>
    );
}
