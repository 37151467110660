import React, { useEffect } from "react";
import ReactDOM from 'react-dom/client';

export default function Banner({title}) {

    useEffect(() => {
        document.title = title
    }, [title])

    return (
        <section className="page-banner py-5 bg-vivid-blue d-flex align-items-center justify-content-center">
            <div className="container py-5">
                <h1 className="text-center text-white m-0 fw-semibold pt-5">{title}</h1>
            </div>
        </section>
    )
}