import React from "react";
import ReactDOM from 'react-dom/client';
import { Outlet } from "react-router-dom";

export default function Layout() {
    return (
        <main id="main-content" className="main-content">
            <Outlet />
        </main>
    )
}